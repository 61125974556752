import { FETCH_PROJECTS } from '../actions/ActionsTypes';

const initialState = [];

const projectsList = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case (FETCH_PROJECTS): {
      return payload.projects;
    }

    default: return state;
  };
};

export default projectsList;