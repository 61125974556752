import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import ContactForm from '../components/ContactForm';


function ContactPage() {

  const { t } = useTranslation();

  return(
    <div className="container main-container">
      <Helmet>
        <meta name="description" content="Un petit mot ? Une remarque ? Des questions ? A tiny word ? An observation ? Any questions ?" />
        <meta name="keywords" content="nicolastiko, stiko, woodwork, wood, woodworking, woodcraft, contact, creations, design, objects, objet, furniture, meuble, artisanat, artisan, art, handmade, artist, artiste" />
        <title>- Stiko - Contact -</title>
        <link rel="canonical" href="https://stikodesign.com/contact" />
      </Helmet>
      <div className="page-title">
        {t('contact.title')}
      </div>
      <div className="row justify-content-center page-description">
        <div className="col-12">
          {t('contact.description')}
        </div>
        <div className="col-12 col-md-8">
          <ContactForm
            formFirstName={t('contact.firstname')}
            formLastName={t('contact.lastname')}
            formEmail={t('contact.email')}
            formMessage={t('contact.message')}
            formRequired={t('contact.required')}
            formValid={t('contact.valid')}
            formSend={t('contact.send')}
          />
        </div>
      </div>
    </div>
  );
};

export default ContactPage;