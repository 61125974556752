import React from 'react';

export const SocialIcon = (props) => {
  const {url, network, children, ...rest} = props
  return(
    <div style={{ marginLeft: 1, marginRight: 1 }}>
        <a
          {...rest}
          href={url}
          aria-label={network}
        >
          <div style={{ height: 30, width: 30 }}>
            {children}
          </div>
        </a>
    </div>
  );
};

export default SocialIcon;