import React, { Component } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import { withTranslation } from 'react-i18next';
import { toast, ToastContainer } from 'react-toastify';

import Header from './HeaderComponent';
import Footer from './FooterComponent';
import HomePage from './pages/HomePage';
import FolioPage from './pages/FolioPage';
import ProjectPage from './pages/ProjectPage';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import LoginPage from './pages/LoginPage';

import { FETCH_PROJECTS } from '../redux/actions/ActionsTypes';
import { API_URL } from '../shared/urlConf';


const mapDispatchToProps = (dispatch) => ({
  fetch_projects: (resp) => dispatch({
    type: FETCH_PROJECTS,
    payload: resp.data
  })
})

class Main extends Component {
    
  componentDidMount() {
    axios.get(API_URL + 'fetch_projects')
    .then(resp => {
      this.props.fetch_projects(resp);
    })
    .catch(() => { toast.error(this.props.t('error.all_projects')) });
  }
  
  render() {
    
    return (
      <div>
        <Header />
          <Switch>
            <Route exact path='/' component={() => <HomePage />} />
            <Route exact path='/about' component={() => <AboutPage />} />
            <Route exact path='/portfolio' component={() => <FolioPage />} />
            <Route path='/portfolio/:projectIdTitle' component={() => <ProjectPage />} />
            <Route exact path='/contact' component={() => <ContactPage />} />
            <Route exact path='/login' component={() => <LoginPage />} />
            <Redirect to="/" />
          </Switch>
        <ToastContainer
          autoClose={5000}
          position="bottom-left"
        />
        <Footer />
      </div>
    );
  }
}

export default withTranslation()(withRouter(connect(null, mapDispatchToProps)(Main)));