import { combineReducers } from 'redux';

import mainImagesById from './mainImagesById.reducer';
import projectsById from './projectsById.reducer';
import projectsList from './projectsList.reducer';
import user from './user.reducer';

export default combineReducers({
  mainImagesById,
  projectsById,
  projectsList,
  user
});