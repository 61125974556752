import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

function AboutPage() {

  const { t } = useTranslation();

  // Workaround to alone some formating and new lines
  const description = t('about.description').split('<br>');

  return(
    <div className="container main-container">
      <Helmet>
        <meta name="description" content="Ébéniste | Graphiste | Passionné | Woodworker | Graphic designer | Enthusiast" />
        <meta name="keywords" content="nicolastiko, stiko, woodwork, wood, woodworking, woodcraft, about, à propos, creations, design, objects, objet, furniture, meuble, artisanat, artisan, art, handmade, artist, artiste" />
        <link rel="canonical" href="https://stikodesign.com/about" />
      </Helmet>
      <div className="page-title">
        {t('about.title')}
      </div>
      <div className="row page-description">
        <div className="col-12 col-md-4">
          {t('about.subtitle')}
          <br /><br />
        </div>
        <div className="col-12 col-md-8">
          {description.map(desc => <p>{desc}</p>)}
        </div>
      </div>
    </div>
  );
};

export default AboutPage;