import { keyBy } from 'lodash';

import { FETCH_PROJECTS } from '../actions/ActionsTypes';

const initialState = {};

const projectsById = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case (FETCH_PROJECTS): {
      return keyBy(payload.projects, 'id');
    }

    default: return state;
  };
};

export default projectsById;