import React, { Component } from 'react';
import { Control, LocalForm, Errors, actions } from 'react-redux-form';
import { FormGroup, FormLabel } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import axios from 'axios';

import { API_URL } from '../../shared/urlConf';


const required = (val) => val && val.length;
const validEmail = (val) => /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,4}$/i.test(val);

class ContactForm extends Component {

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    axios.post(API_URL + 'process_email', {
      "firstname": values.firstname,
      "lastname": values.lastname,
      "email": values.email,
      "message": values.message
    })
    .then(({ data }) => {
      if (data === 'done') {
        toast.dark(this.props.t('success.message'));
        this.formDispatch(actions.reset('contactForm'));
      } else {
        toast.info(this.props.t('info.message'));
      }
    })
    .catch(() => { toast.error(this.props.t('error.message')) });
  };

  render() {

    const { formFirstName, formLastName, formEmail, formMessage, formRequired, formValid, formSend } = this.props;

    return(
      <LocalForm
        model="contactForm"
        className="mt-3"
        onSubmit={(values) => this.handleSubmit(values)}
        getDispatch={(dispatch) => this.formDispatch = dispatch}
      >
        <div className="row">
          <div className="col-12 col-md-6">
            <FormGroup>
              <FormLabel>{formFirstName}</FormLabel>
                <Control.text 
                  model='.firstname'
                  className='form-control'
                  id='firstname'
                  name='firstname'
                  validators={{
                    required
                  }}
                />
                <Errors
                  className="text-danger"
                  model=".firstname"
                  show="touched"
                  messages={{
                    required: formRequired
                  }}
                />
            </FormGroup>
          </div>
          <div className="col-12 col-md-6">
            <FormGroup>
              <FormLabel>{formLastName}</FormLabel>
                <Control.text 
                  model='.lastname'
                  id='lastname'
                  className='form-control'
                  name='lastname'
                  validators={{
                    required
                  }}
                  />
                <Errors
                  className="text-danger"
                  model=".lastname"
                  show="touched"
                  messages={{
                    required: formRequired
                  }}
                />
            </FormGroup>
          </div>
        </div>
        <FormGroup>
          <FormLabel>{formEmail}</FormLabel>
            <Control.text 
              model='.email'
              id='email'
              className='form-control'
              name='email'
              validators={{
                required, 
                validEmail
              }}
              />
            <Errors
              className="text-danger"
              model=".email"
              show="touched"
              messages={{
                required: formRequired,
                validEmail: formValid
              }}
            />
        </FormGroup>
        <FormGroup>
          <FormLabel>{formMessage}</FormLabel>
            <Control.textarea 
              model='.message'
              id='message'
              className='form-control'
              name='message'
              rows="12"
            />
        </FormGroup>
        <FormGroup>
          <button type="submit" className="submit-btn mb-2">
            {formSend}
          </button>
        </FormGroup>
      </LocalForm>
    );
  };
}

export default withTranslation()(ContactForm);