import React from 'react';

export const Loading = () => {
  return(
    <div className="preloader" id="preloader">
      <div className="loader">
        <div className="loader-inner">
        </div>
      </div>
    </div>
  );
};

export default Loading;