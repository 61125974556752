import React from 'react';

import { SocialIcon } from './components/SocialIcon';
import {ReactComponent as MailLogo} from '../icons/email.svg';
import {ReactComponent as EtsyLogo} from '../icons/etsy.svg';
import {ReactComponent as FacebookLogo} from '../icons/facebook.svg';
import {ReactComponent as InstaLogo} from '../icons/instagram.svg';

const Footer = () => {  
  return (
    <div className="container divider-top fixed-bottom">
      <div className="row justify-content-center">
        <SocialIcon
          url="https://www.instagram.com/stiko_design/"
          target="_blank" rel="noreferrer noopener"
          children={<InstaLogo/>}
        />
        <SocialIcon
          url="https://www.facebook.com/StikoDesign"
          target="_blank" rel="noreferrer noopener"
          children={<FacebookLogo/>}
        />
        <SocialIcon
          url="https://www.etsy.com/ca-fr/shop/StikoDesign?ref=seller-platform-mcnav"
          target="_blank" rel="noreferrer noopener"
          children={<EtsyLogo/>}
        />
        
        <SocialIcon
          url="/contact"
          children={<MailLogo/>}
        />
      </div>
    </div>
  );
};

export default Footer;