import { RESET_USER, SET_USER } from '../actions/ActionsTypes';

const initialState = {
    id: "",
    first_name: "",
    role: "",
}

const user = (state = initialState, action) => {
  const {type, payload } = action

  switch (type) {
    case SET_USER: {
      return {
        ...payload,
      }
    }

    case RESET_USER: {
      return initialState;
    }

    default: return state;
  }

}

export default user