import React from "react";
import { Link } from 'react-router-dom';


const cont = {
  position: "relative"
};

const FolioImage = ({ photo, title, desc }) => {

  return (
    <div
      style={{ height: photo.height, width: photo.width, ...cont }}
      className="grid-item"
    >
      <Link  to={`portfolio/${photo.project_id}__${photo.title}`}>
        <img
          alt={photo.title}
          {...photo}
        />
        <div className="grid-hover">
          <svg viewBox="0 0 100 20" className="svg-title">
            <text x="0" y="15">{title}</text>
          </svg>
          <p>{desc}</p>
        </div>
      </Link>
    </div>
  );
};

export default FolioImage;
