import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Gallery from 'react-photo-gallery';
import Typist from 'react-typist';
import { values, sortBy } from 'lodash';

import FolioImage from '../components/FolioImage';
import { FOLIO_IMG_URL } from '../../shared/urlConf';

function HomePage() { 
  
  const { t, i18n } = useTranslation();
  const { projectsById, mainImagesById } = useSelector(state => ({
    projectsById: state.projectsById,
    mainImagesById: state.mainImagesById
  }));

  // Workaround to alone some formating and new lines
  const title = t('home.title').split('<br>');

  const updateImage = (img) => {
    return {
      ...img,
      title: projectsById[img.project_id].title,
      subtitle: projectsById[img.project_id].subtitle[i18n.language],
      alt: projectsById[img.project_id].title,
      src: FOLIO_IMG_URL + img.src,
      project_date:  projectsById[img.project_id].update_date
    };
  };

  const imageRenderer = useCallback(
    ({ key, photo }) => {
      return (
      <FolioImage
        key={key}
        photo={photo}
        title={photo.title}
        desc={photo.subtitle}
      />)
      },
    []
  );
  
  const photos = sortBy(
    values(mainImagesById).map(img => updateImage(img)),
  ['project_date', 'project_id'])
  .reverse()
  .slice(0,3);

  return(
    <div className="container main-container">
      <div className="page-title">
        {title.map(t => <p key={t}>{t}</p>)}
        <Typist avgTypingDelay={100} stdTypingDelay={40} key={i18n.language}>
          <span>{t('home.title.animation1')}</span>
          <Typist.Backspace count={t('home.title.animation1').length} delay={400} />
          <span>{t('home.title.animation2')}</span>
          <Typist.Backspace count={t('home.title.animation2').length} delay={400} />
          <span>{t('home.title.animation3')}</span>
          <Typist.Backspace count={t('home.title.animation3').length} delay={400} />
          <span>{t('home.title.animation4')}</span>
        </Typist>
      </div>
      <div className="row justify-content-center page-description">
        <div className="col-12">
          {t('home.description')}
        </div>
      </div>
      <Gallery
        photos={photos}
        renderImage={imageRenderer}
      />
      <div className="row page-description">
        <div className="col-12 right-link">
          <Link to='/portfolio'>{t('home.more')}</Link>
        </div>
      </div>
    </div>
  );
};

export default HomePage;