import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { SET_USER } from '../../redux/actions/ActionsTypes';
import { API_URL } from '../../shared/urlConf';


const defaultUser = {
  "email": "",
  "password": ""
};

function LoginPage() {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [validated, setValidated] = useState(false);
  const [email, setEmail] = useState(defaultUser.email);
  const [password, setPassword] = useState(defaultUser.password);

  const resetUser = () => {
    setEmail(defaultUser.email);
    setPassword(defaultUser.password);
  };

  const userLogin = () => {
    axios.post(API_URL + 'customer_signin', {
      "email": email,
      "password": password
    })
    .then(({ data }) => {
      dispatch({
        type: SET_USER,
        payload: data
      });
    })
    .then(() => {
      history.push({
        pathname:  "/home",
        state: {
          response: "Logged in!" 
        } 
      });
    })
    .catch((err) => { 
      switch (err.response.status) {
        case 401: toast.error(t("error.401")); break;
        case 404: toast.error(t("error.404")); break;
        default: toast.error(t("error.default"));
      }
    });
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === true) {
      userLogin();
      resetUser();
      setValidated(false);
    } else {
      setValidated(true);
    }
    event.preventDefault();
  };

  return(
    <div className="container main-container">
      <div className="page-title">
        Login
      </div>
      <div className="row justify-content-center page-description">
        <div className="col-12 col-md-8">
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>{t('signup.email')}</Form.Label>
              <Form.Control
                required
                type="email"
                value={email}
                onChange={e => { setEmail(e.target.value); }}
              />
              <Form.Control.Feedback type='invalid'>
                {t('mandatory.message')}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>{t('signup.password')}</Form.Label>
              <Form.Control
                required
                type="password"
                value={password}
                onChange={e => { setPassword(e.target.value); }}
              />
              <Form.Control.Feedback type='invalid'>
                {t('mandatory.message')}
              </Form.Control.Feedback>
            </Form.Group>
            <button
              type="submit"
              className="submit-btn row-btn mb-2"
            >
              LOGIN
            </button>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;