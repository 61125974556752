import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import Gallery from 'react-photo-gallery';
import ScrollToTop from 'react-scroll-up';
import { values, sortBy } from 'lodash';

import FolioImage from '../components/FolioImage';
import upArrow from '../../shared/images/up_arrow_round.png';
import { FOLIO_IMG_URL } from '../../shared/urlConf';

function FolioPage() { 
  
  const { t, i18n } = useTranslation();
  const { projectsById, mainImagesById } = useSelector(state => ({
    projectsById: state.projectsById,
    mainImagesById: state.mainImagesById
  }));

  const updateImage = (img) => {
    return {
      ...img,
      title: projectsById[img.project_id].title,
      subtitle: projectsById[img.project_id].subtitle[i18n.language],
      alt: projectsById[img.project_id].title,
      src: FOLIO_IMG_URL + img.src,
      project_date:  projectsById[img.project_id].update_date
    };
  };

  const imageRenderer = useCallback(
    ({ key, photo }) => {
      return (
      <FolioImage
        key={key}
        photo={photo}
        title={photo.title}
        desc={photo.subtitle}
      />)
      },
    []
  );

  const photos = sortBy(
    values(mainImagesById).map(img => updateImage(img)),
    ['project_date', 'project_id'])
  .reverse();

  return(
    <div className="container main-container">
      <Helmet>
        <meta name="description" content="Vous pourrez trouver ici l'ensemble de mes créations. N'hésitez pas à jeter un oeil sur chaque projet ! | You can see all of my work here. Go ahead and take a look at each and every projects!" />
        <meta name="keywords" content="nicolastiko, stiko, woodwork, wood, woodworking, woodcraft, portfolio, folio, creations, design, objects, objet, furniture, meuble, artisanat, artisan, art, handmade, artist, artiste" />
        <title>- Stiko - Portfolio -</title>
        <link rel="canonical" href="https://stikodesign.com/portfolio" />
      </Helmet>
      <div className="page-title">
        {t('folio.title')}
      </div>
      <div className="row justify-content-center page-description">
        <div className="col-12">
          {t('folio.description')}
        </div>
      </div>
      <Gallery
        photos={photos}
        renderImage={imageRenderer}
      />
      <ScrollToTop showUnder={160} style={{'bottom':'70px', 'right': '10px'}}>
        <img src={upArrow} alt="UP" width="30px"/>
      </ScrollToTop>
    </div>
  );
};

export default FolioPage;