import React, { useState } from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';


const LanguageSelector = () => {
  
  const [lang, setLang]=useState('fr');
  const { i18n } = useTranslation();

  const changeLanguage = (event) => {
    i18n.changeLanguage(event.target.innerText.toLowerCase());
    setLang(event.target.innerText.toLowerCase());
  }

  return (
    <DropdownButton
      id="language-button"
      title={lang.toUpperCase()}
      size='sm'
      className="ml-1"
    >
      <Dropdown.Item as="button" onClick={changeLanguage}>FR</Dropdown.Item>
      <Dropdown.Item as="button" onClick={changeLanguage}>EN</Dropdown.Item>
    </DropdownButton>
  );
};

export default LanguageSelector;