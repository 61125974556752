import React, { useState } from 'react';
import { Navbar, NavbarBrand, Nav, NavbarToggler, NavItem, Collapse } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import LanguageSelector from './components/LanguageSelector';

import { RESET_USER } from '../redux/actions/ActionsTypes';
import logo from '../shared/images/logo.png';
import { LogoutIcon } from '../shared/images/icons';


const Header = () => { 

  const [isNavOpen, setIsNavOpen] = useState(false);
  const toggleNav = () => setIsNavOpen(!isNavOpen);
  
  const handleOnClick = () => {
    setIsNavOpen(false);
  };

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useSelector(state => ({
    user: state.user
  }));

  const handleLogout = () => dispatch({
    type: RESET_USER,
    payload: null
  });

  return (
    <Navbar light expand='md'>
      <div className="container divider-bottom">
        <NavbarBrand className='mr-auto' href="/">
          <img src={logo} alt="Stiko" />
        </NavbarBrand>
        <NavbarToggler onClick={toggleNav} />
        <Collapse isOpen={isNavOpen} navbar className="justify-content-end">
          <Nav navbar>
            <NavItem>
              <NavLink className="nav-link" to="/home" onClick={handleOnClick} >
                {t('menu.home')}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className="nav-link" to="/portfolio" onClick={handleOnClick} >
                {t('menu.portfolio')}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className="nav-link" to="/about" onClick={handleOnClick} >
                {t('menu.about')}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className="nav-link" to="/contact" onClick={handleOnClick} >
                {t('menu.contact')}
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
        { user.id !== "" ? 
          <a
            href="/"
            className="nav-link"
            onClick={() => handleLogout()}
          >
            <LogoutIcon />
          </a>
          :
          null }
        <LanguageSelector />
      </div>
    </Navbar>
  );
};

export default Header;