import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import ImageGallery from 'react-image-gallery';
import { toast } from 'react-toastify';

import { BASE_IMG_URL, THUMBNAIL_URL, API_URL } from '../../shared/urlConf';


function ProjectPage() {

  const { t, i18n } = useTranslation()
  
  const { projectIdTitle } = useParams();
  const projectId = parseInt(projectIdTitle.split('__')[0]);

  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [description, setDescription] = useState('');
  const [images, setImages] = useState([]);
  const [nextProject, setNextProject] = useState({});
  const [prevProject, setPrevProject] = useState({});

  const updateImage = (img) => {
    return {
      original: BASE_IMG_URL + img.src,
      thumbnail: THUMBNAIL_URL + img.src,
    };
  };

  useEffect(() => {
    axios.get(API_URL + 'fetch_one_project/' + projectId)
      .then(({ data }) => {
          setTitle(data.project.title);
          setSubtitle(data.project.subtitle);
          setDescription(data.project.description);
          setImages(data.images.map(img => updateImage(img)))
          setNextProject({
            available: data.project.nextId === null ? false : true,
            id: data.project.nextId,
            title: data.project.nextTitle
          })
          setPrevProject({
            available: data.project.prevId === null ? false : true,
            id: data.project.prevId,
            title: data.project.prevTitle
          })
          document.title = `- Stiko - ${data.project.title} -`
      })
      .catch(() => { toast.error(t('error.one_project')) });
      
  }, [projectId, t]);

  return(
    <div className="container main-container">
      <Helmet>
        <meta name="description" content={title + " " + description[i18n.language] } />
        <meta name="keywords" content="nicolastiko, stiko, woodwork, wood, woodworking, woodcraft, portfolio, creations, design, objects, objet, furniture, meuble, artisanat, artisan, art, handmade, artist, artiste, about, à propos" />
      </Helmet>
      <div className="page-title">
        {title}
      </div>
      <div className="row page-description">
        <div className="col-12 col-md-4">
          {subtitle[i18n.language]}
          <br /><br />
        </div>
        <div className="col-12 col-md-8">
          {description[i18n.language]}
        </div>
      </div>
      <ImageGallery
        items={images}
        showPlayButton={false}
      />
      <div className="row page-description">
        <div className="col-12 both-link">
          {prevProject.available ? 
            <Link to={`${prevProject.id}__${prevProject.title}`}>{'<'} {prevProject.title}</Link>
            : <div></div>
          }
          <Link to={'/portfolio'}>Portfolio</Link>
          {nextProject.available ?
            <Link to={`${nextProject.id}__${nextProject.title}`}>{nextProject.title} {'>'}</Link>
            : <div></div>
          }
        </div>
      </div>
    </div>
  );
};

export default ProjectPage;