import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

import Main from './components/MainComponent';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ConfigureStore } from './redux/configureStore';


const store = ConfigureStore();
class App extends Component {

  render() {
    return (
        <Provider store={store}>
          <BrowserRouter>
            <div className="App">
              <Helmet>
                <meta charSet="utf-8" />
                <meta name="description" content="Mon nouveau site web, enfin ! | My new website, finally !" />
                <meta name="keywords" content="nicolastiko, stiko, woodwork, wood, woodworking, woodcraft, index, home, creations, design, objects, objet, furniture, meuble, artisanat, artisan, art, handmade, artist, artiste" />
                <title>- Stiko -</title>
              </Helmet>
              <Main />
            </div>
          </BrowserRouter>
        </Provider>
    );
  }
}

export default App;
